import instance from './config';

const setCurrentUser = (user) => {
	return instance.post('/currentuser', user);
};

const getCurrentUser = () => {
	return instance.get('/currentuser');
};

const profileUpdate = (uid, data) => {
	return instance.post(`/user/profile/${uid}`, data);
};

const bioUpdate = (uid, data) => {
	return instance.post(`/user/bio/${uid}`, data);
};

const adminGetUser = (uid) => {
	return instance.get(`/admin/user/${uid}`);
};

const adminGetUsers = (page = 1, sortDir = 'asc', sortCol = 'display_order') => {
	return instance.get(`/admin/users?page=${page}&sortDir=${sortDir}&sortCol=${sortCol}`);
};

const resetPasswordReq = (data) => {
	return instance.post('/request-password-reset', data);
}

const resetUserPassword = (hash, data) => {
	return instance.post(`/password-reset/${hash}`, data);
}

export {
	setCurrentUser,
	getCurrentUser,
	profileUpdate,
	adminGetUser,
	adminGetUsers,
	bioUpdate,
	resetPasswordReq,
	resetUserPassword
}

